<template>
  <v-container fluid>
    <div class="pb-4">
      <h1 class="text-h4">{{ $lang.header.historyDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters class="fill-height">
        <v-col
          cols="12"
          sm="12"
          md="10"
          class="fill-height"
        >
          <v-card class="pa-2 fill-height">
            <v-row wrap no-gutters justify="space-between" class="pb-1">
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="history.resourceName"
                  outlined
                  dense
                  :label="$lang.labels.name"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pl-md-1"
              >
                <v-text-field
                  v-model="history.resourceTypeLabel"
                  outlined
                  dense
                  :label="$lang.labels.resourceType"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="pl-md-1"
              >
                <v-text-field
                  v-model="history.action"
                  outlined
                  dense
                  :label="$lang.labels.action"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="history.createdOn"
                  outlined
                  dense
                  :label="$lang.labels.createdOn"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
            <v-row no-gutters wrap justify="space-between">
              <v-col
                cols="6"
                class="text-left"
              >
                <div class="d-inline-flex align-center">
                  <v-btn
                    class="button-default-width"
                    color="secondary"
                    :to="{ name: 'history', params: { lang: $lang.current_lang } }"
                  >
                    <v-icon
                      right
                      dark
                      class="ma-0"
                    >
                      mdi-arrow-left
                    </v-icon>
                    <span class="ml-1">{{ $lang.actions.back }}</span>
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <v-btn
                  :disabled="disableRestore"
                  color="accent"
                  class="ml-1 color-accent-text"
                  @click="restoreResourceFromHistory(history.resource, history.resourceType, resourcesTypes[history.resourceType], $router, $options.filters.formatDateTime(new Date()))"
                >{{ $lang.actions.restoreCreate }}</v-btn>
              </v-col>
              <v-col
                v-if="isNameTaken"
                cols="12"
                class="text-center align-center mt-2"
              >
                <v-alert color="info" data-cy="A">{{ $lang.hints.restoreHint }}</v-alert>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  getHistoryByIdUsingGET as getHistory,
  getModulesUsingGET as getModules, getPluginsUsingGET as getPlugins,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessesUsingGET as getProcesses,
  getSettingsUsingGET as getSettings,
  getTextTemplatesUsingGET as getTemplates,
  getTranslationsUsingGET as getTranslations,
  getTriggerCronsUsingGET as getCrons, getTriggerMessagingsUsingGET as getMessagings,
  getTriggerRestsUsingGET as getRests,
  getValidationRulesUsingGET as getValidationRules,
  getTriggerEventHandlersUsingGET as getEventHandlers,
  getLibrariesUsingGET as getLibraries,
  getEntitiesUsingGET as getEntities
} from '@/utils/api'
import { restoreResourceFromHistory } from '@/utils/helpers'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      err: '',
      success: '',
      loading: true,
      history: {
        action: '',
        createdOn: '',
        id: 0,
        resource: '',
        resourceId: 0,
        resourceName: '',
        resourceType: '',
        resourceTypeLabel: '',
        userId: 0
      },
      isNameTaken: false,
      disableRestore: false,
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'translationCreate',
        PROCESS: 'processCreate',
        PROCESS_CREDENTIAL: 'credentialCreate',
        TEXT_TEMPLATE: 'templateCreate',
        TRIGGER_CRON: 'cronCreate',
        TRIGGER_REST: 'restCreate',
        VALIDATION_RULE: 'validationCreate',
        PLUGIN: 'pluginCreate',
        TRIGGER_MESSAGING: 'messagingCreate',
        TRIGGER_EVENT_HANDLER: 'eventHandlerCreate',
        SETTING: 'settingCreate',
        MODULE: 'moduleCreate',
        LIBRARY: 'libraryCreate',
        ENTITY: 'entityCreate'
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'historyEdit'

    if (this.isEdit && this.$route.params.id) {
      this.loading = true
      getHistory({ id: this.$route.params.id })
        .then((res) => {
          this.history = res.data.data

          this.history.createdOn = new Date(this.history.createdOn).toLocaleString('de-DE')

          this.history.resourceTypeLabel = this.$lang.status[this.history.resourceType]

          this.checkResourceName(this.history.resourceType, this.history.resourceName)

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    restoreResourceFromHistory,
    checkResourceName(type, val) {
      this.disableRestore = false

      switch (type) {
      case 'GLOBAL_TRANSLATION':
        this.searchTranslationsFunction(val)
        break
      case 'MODULE':
        this.searchModulesFunction(val)
        break
      case 'PROCESS':
        this.searchProcessesFunction(val)
        break
      case 'PROCESS_CREDENTIAL':
        this.searchCredentialsFunction(val)
        break
      case 'SETTING':
        this.searchSettingsFunction(val)
        break
      case 'TEXT_TEMPLATE':
        this.searchTextTemplatesFunction(val)
        break
      case 'TRIGGER_CRON':
        this.searchCronsFunction(val)
        break
      case 'TRIGGER_REST':
        this.searchRestsFunction(val)
        break
      case 'VALIDATION_RULE':
        this.searchValidationRulesFunction(val)
        break
      case 'PLUGIN':
        this.searchPluginsFunction(val)
        break
      case 'TRIGGER_MESSAGING':
        this.searchMessagingsFunction(val)
        break
      case 'TRIGGER_EVENT_HANDLER':
        this.searchEventHandlersFunction(val)
        break
      case 'LIBRARY':
        this.searchLibrariesFunction(val)
        break
      case 'ENTITY':
        this.searchEntityFunction(val)
        break
      default:
        this.disableRestore = true
      }
    },
    searchEntityFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getEntities(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchLibrariesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getLibraries(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchEventHandlersFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getEventHandlers(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchTranslationsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTranslations(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchModulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getModules(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchProcessesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getProcesses(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchCredentialsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCredentials(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchSettingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getSettings(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchTextTemplatesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTemplates(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchCronsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCrons(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchRestsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getRests(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchValidationRulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getValidationRules(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchPluginsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getPlugins(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchMessagingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getMessagings(obj)
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length > 0) {
            this.isNameTaken = res.data.data.items.find((item) => item.name === this.history.resourceName)
          } else {
            this.isNameTaken = false
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  }
}
</script>
<style lang="scss">
.side-border {
  &-left {
    border-left: 1px solid var(--v-secondary-base);
  }
  &-right {
    border-right: 1px solid var(--v-secondary-base);
  }
}

.hideNumberArrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
